import { useEffect, useRef, useState } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopyRounded";

type AuditLogDocumentProps = {
  pageCount: number;
  documentURL: string;
  documentName: string | null;
};

const AuditLogDocument = ({ pageCount, documentURL, documentName }: AuditLogDocumentProps) => {
  if (!documentName) return null;

  const shouldDisplayPagesText = pageCount > 1;

  return (
    <div className="flex flex-col gap-5 mb-5">
      <a className="text-brand-purple hover:underline" href={documentURL} target="__blank">
        <TruncatedFilename filename={documentName || ""} maxWidth={600} />
      </a>
      <div className="border border-slate-100 rounded-lg p-[0.1rem] h-[7rem] w-[5.357rem]">
        <div className="bg-slate-100 rounded-sm flex flex-col justify-center items-center h-full w-full gap-2">
          <FileCopyIcon className="text-brand-purple" fontSize="small"></FileCopyIcon>
          {shouldDisplayPagesText ? <p className="text-xs text-slate-500">{`${pageCount} pages`}</p> : null}
        </div>
      </div>
    </div>
  );
};

export const TruncatedFilename = ({ filename, maxWidth = 200 }: { filename: string; maxWidth?: number }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const filenameRef = useRef<HTMLDivElement | null>(null);

  const checkTruncation = () => {
    if (filenameRef.current) {
      setIsTruncated(filenameRef?.current?.scrollWidth > maxWidth);
    }
  };

  useEffect(() => {
    checkTruncation(); // Initial check
    window.addEventListener("resize", checkTruncation); // Add resize listener

    return () => {
      window.removeEventListener("resize", checkTruncation); // Clean up listener
    };
  }, [filename, maxWidth]);

  const extensionMatch = filename?.match(/\.[^.]*$/);
  const extension = extensionMatch ? extensionMatch[0] : "";
  const baseName = filename?.slice(0, -extension.length);

  const start = baseName?.substring(0, baseName.length / 2);
  const end = baseName?.substring(baseName.length / 2);

  return filename ? (
    <div className={`text-xs flex items-center w-0 min-w-full overflow-hidden`} ref={filenameRef}>
      {isTruncated ? (
        <>
          <span className="line-clamp-1 overflow-hidden whitespace-nowrap text-clip">{start}</span>
          <span className="flex-shrink-0 mx-[0.025rem]">...</span>
          <span className="mr-[0.025rem] line-clamp-1 overflow-hidden whitespace-nowrap text-clip">{end}</span>
          <span className="flex-shrink-0">{extension}</span>
        </>
      ) : (
        <span>{filename}</span>
      )}
    </div>
  ) : null;
};

export default AuditLogDocument;
