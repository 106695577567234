const BusinessHeader = ({ name, website }: { name: string | null; website: string | null }) => (
  <div className="flex flex-col gap-y-2 min-w-full w-0 overflow-hidden">
    {name && <span className="text-xl font-semibold leading-tight">{name}</span>}
    {website && (
      <a
        href={website.startsWith("http") ? website : `https://${website}`}
        target="_blank"
        rel="noreferrer"
        className="text-xs text-brand-purple truncate hover:underline"
      >
        {website}
      </a>
    )}
  </div>
);

export default BusinessHeader;
