import { snakeCaseToProperCase } from "@/utils";
import { Policy } from "@mui/icons-material";
import { ReactNode } from "react";

export type PolicyType = {
  match_type: string;
  url: string | null;
  explanation: string | null;
  sourceComponent?: ReactNode;
};

const WebsitePolicyCard = ({ match_type, url, explanation, sourceComponent }: PolicyType) => {
  const urlToUse = url && !url.startsWith("http") ? `https://${url}` : url;

  return (
    <div className={"flex flex-col gap-y-2 border rounded-lg p-3 border-slate-200"}>
      <div className="flex items-center gap-x-2">
        <Policy sx={{ fontSize: "1rem" }} className="text-slate-600" />
        {urlToUse ? (
          <a href={urlToUse} target="_blank" rel="noreferrer" className="text-brand-purple">
            {snakeCaseToProperCase(match_type)}
          </a>
        ) : (
          <span className="text-slate-700">{snakeCaseToProperCase(match_type)}</span>
        )}
        {sourceComponent ?? null}
      </div>
      {explanation && <p className="text-slate-700">{explanation}</p>}
    </div>
  );
};

export default WebsitePolicyCard;
