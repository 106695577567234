// @ts-nocheck
import { ChangeEvent, Fragment, Key, useEffect, useState } from "react";
import ParchaLogo from "../../assets/Parcha_Logo_With_Name.png";
import BusinessDueDiligenceImage from "../../assets/biz report skeleton 1.svg";
import WebsiteCard from "./WebsiteCard";
import {
  FactoryRounded,
  LanguageRounded,
  PolicyRounded,
  PeopleRounded,
  WarningRounded,
  Pin,
  CheckRounded,
  CancelRounded,
  BusinessRounded,
  PublicRounded,
  AutoAwesome,
  ImageSearch,
  NewspaperRounded,
  VisibilityRounded,
  SubdirectoryArrowRightRounded,
  CheckCircleRounded,
  WorkspacePremiumRounded,
  GroupRounded,
  TaskRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  PlagiarismRounded,
  CloseRounded,
  PersonRounded,
} from "@mui/icons-material";
import { Dialog, Transition } from "@headlessui/react";
import FindingsSource from "@/components/TailwindComponents/FindingsSource";
import FindingsSection from "./FindingsSection";
import WebPresenceCard from "./WebPresenceCard";
import InstagramProfileCard from "./InstagramProfileCard";
import XTwitterProfileCard from "./XTwitterProfileCard";
import LinkedInCompanyCard from "./LinkedInCompanyCard";
import FacebookProfileCard from "./FacebookProfileCard";
import Alert from "./Alert";

import SourceTagCircle from "./SourceTagCircle";
import WebsitePolicyCard from "./WebsitePolicyCard";

import { twJoin, twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";
import WrappedValue from "./WrappedValue";
import AdverseMediaHit from "./AdverseMediaHit";
import SanctionsWatchlistCard from "./SanctionsWatchlistCard";
import AnimatedParagraph from "./AnimatedParagraph";
import { motion } from "framer-motion";
import { BDDInputWithOverlappingLabel } from "../V2_Home_Page/PublicReportForm";
import COUNTRIES from "@/data/countries";
import { v4 as uuidv4 } from "uuid";
import { useParchaApi } from "@/hooks/useParchaApi";
import SmartSkeleton from "./SmartSkeleton";
import { CircularProgress } from "@mui/material";
import CrunchbaseCompanyCard from "./CrunchbaseCompanyCard";
import CheckStatusMessage from "./CheckStatusMessage";
import { Tooltip } from "@mui/material";
import UpsellSectionWrapper from "./UpsellSectionWrapper";
import LegalBusinessEntitiesImg from "../../assets//LegalBusinessEntities.png";
import BusinessOwnersImg from "../../assets//BusinessOwners.png";
import IncorporationDocsImg from "../../assets//IncorporationDocs.png";
import BusinessBasicProfile from "../V2_Home_Page/PublicBDDComponents/sections/BusinessBasicProfile";
import {
  BUSINESS_PROFILE_CHECK_ID,
  WEB_PRESENCE_CHECK_ID,
  ADDRESS_CHECK_ID,
  HIGH_RISK_COUNTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  ADVERSE_MEDIA_CHECK_ID,
  SANCTIONS_WATCHLIST_CHECK_ID,
  POLICY_CHECK_ID,
  MCC_CHECK_ID,
  BUSINESS_OWNERS_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  SOURCE_OF_FUNDS_CHECK_ID,
  shimmerEffectClassNames,
} from "@/constants/vars";
import Addresses from "../V2_Home_Page/PublicBDDComponents/sections/Addresses";
import HighRiskCountries from "../V2_Home_Page/PublicBDDComponents/sections/HighRiskCountries";
import CheckSectionStatusMessage from "../V2_Home_Page/PublicBDDComponents/sections/CheckSectionStatusMessage";
import GenericSkeleton from "../V2_Home_Page/PublicBDDComponents/sections/GenericSkeleton";
import DocumentList from "../V2_Home_Page/PublicBDDComponents/sections/DocumentList";
import DocumentValidation from "./DocumentValidation";
import InstagramWebsiteCard from "./InstagramWebsiteCard";

const ParchaReport = ({ agent, jobMetadata, disableAnimation = false }: any) => {
  if (!jobMetadata) return null;

  const job = jobMetadata.job;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    business_name: job?.input_payload?.self_attested_data.business_name || "",
    registered_business_name: job?.input_payload?.self_attested_data.registered_business_name || "",
    description: job?.input_payload?.self_attested_data.description || "",
    country_of_operation: job?.input_payload?.self_attested_data.address_of_operation?.country_code || "",
  });
  const [formErrors, setFormErrors] = useState<{ country_of_operation: string | null }>({
    country_of_operation: null,
  });

  const [error, setError] = useState("");
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [activeSource, setActiveSource] = useState<string | null>(null);
  const [showingSources, setShowingSources] = useState(false);
  const [selectedDocumentCheck, setSelectedDocumentCheck] = useState<string | null>(null);

  const envTier = import.meta.env.VITE_ENV_TIER;
  const endpointUrl =
    envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "demo.parcha.ai";

  useEffect(() => {
    if (job.status === "complete") {
      setShowingSources(true);
    }
  }, [job.status]);

  const toggleShowingSources = () => {
    setShowingSources(!showingSources);
  };

  const parchaApi = useParchaApi();

  const onFieldChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.value;
    if (e.target.name === "country_of_operation") {
      if (value === "") {
        setFormErrors({
          ...formErrors,
          [e.target.name]: "Please select a country of operation",
        });
      } else {
        setFormErrors({ ...formErrors, [e.target.name]: null });
      }
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: null });
    }
    setFormData({ ...formData, [e.target.name]: value.replaceAll("https://", "").replaceAll("http://", "") });
  };

  const handleOnSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    setIsGeneratingReport(true);
    const input = {
      agent_key: job?.agent_id,
      kyb_schema: {
        id: `parcha-${uuidv4().replace(/-/g, "").substring(0, 8)}`,
        self_attested_data: {
          business_name: formData.business_name.length > 0 ? formData.business_name : undefined,
          registered_business_name:
            formData.registered_business_name.length > 0 ? formData.registered_business_name : undefined,
          address_of_operation: formData.country_of_operation
            ? {
                country_code: formData.country_of_operation,
              }
            : undefined,
          description: formData.description.length > 0 ? formData.description : undefined,
        },
      },
      run_in_parallel: true,
    };

    parchaApi.enqueueAgent(endpointUrl, input, "kyb").then((res) => {
      if (res.job_id) {
        window.location =
          job.agent_id === "public-bdd" ? `/reports/${res.job_id}` : `/jobs/${job.agent_id}/${res.job_id}`;
      } else {
        setError(res.message);
      }
      setIsGeneratingReport(false);
    });
  };

  const agentInstanceId = "kyb" in jobMetadata ? Object.keys(jobMetadata["kyb"])[0] : null;
  const agentInstance = agentInstanceId ? jobMetadata["kyb"][agentInstanceId] : null;
  const sourcesValues = agentInstance?.sources;
  const sourcesDictionary = sourcesValues?.reduce(
    (acc: { [x: string]: any }, source: { source_id: string | number }, index: number) => {
      acc[source.source_id] = { ...source, id: index + 1 };
      return acc;
    },
    {},
  );

  const website = jobMetadata?.job?.input_payload?.self_attested_data?.website;

  const handleSourceClick = (id) => {
    const sourceElement = document.getElementById(`source-${id}`);
    if (sourceElement) {
      sourceElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    } else {
      setShowingSources(true);
      setTimeout(() => {
        handleSourceClick(id);
      }, 200);
    }
  };

  if (job?.status === "failed" && job?.agent_id === "public-bdd") {
    return (
      <div className="w-[400px] flex justify-center py-[1.75rem] mx-auto">
        <div className=" flex flex-col items-center gap-y-[3.75rem]">
          <div className="flex items-center flex-col gap-y-[2.5rem]">
            <div className="flex items-center justify-center w-[4rem] h-[4rem] bg-indigo-100 rounded-full">
              <WarningRounded sx={{ fontSize: "2rem" }} className="text-brand-purple" />
            </div>
            <div className="flex flex-col gap-y-5">
              <span className="text-center font-semibold text-xl">
                We were unable to gather enough information from the website provided.
              </span>
              <span className="text-center text-base text-slate-500">
                Please check that the information is correct and provide any additional information for us to do an
                updated search.
              </span>
            </div>
          </div>
          <form onSubmit={handleOnSubmit} className="w-full flex flex-col gap-y-10 justify-center mx-auto">
            <div className="flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-8">
                <BDDInputWithOverlappingLabel
                  label="Business Name"
                  placeholder="Enter a business of interest"
                  required
                  fieldName="business_name"
                  value={formData.business_name}
                  onChangeHandler={onFieldChangeHandler}
                  icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                />
                <BDDInputWithOverlappingLabel
                  label={
                    <>
                      Required Business Name <span className="font-thin">(Optional)</span>
                    </>
                  }
                  placeholder="Provide the name used for business registration"
                  fieldName="registered_business_name"
                  value={formData.registered_business_name}
                  onChangeHandler={onFieldChangeHandler}
                  icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                />
                <div className="relative">
                  <label
                    htmlFor="description"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate"
                  >
                    Business Description
                    <span className="text-red-500 ml-0.5">*</span>
                  </label>
                  <textarea
                    rows={4}
                    name="description"
                    id="description"
                    required
                    placeholder="Give a brief explanation of what the business does"
                    onChange={onFieldChangeHandler}
                    className="box-border block w-full rounded-md py-3 text-slate-900 ring-1 ring-inset ring-slate-100 border border-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple"
                    value={formData.description}
                  />
                </div>
                <div className="relative">
                  <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate">
                    Country <span className="text-red-500 ml-0.5">*</span>
                  </label>

                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <PublicRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
                  </div>

                  <select
                    id="country_of_operation"
                    name="country_of_operation"
                    className={twMerge(
                      "box-border block w-full rounded-md py-3 pl-12 ring-1 ring-inset ring-slate-100 border border-slate-300 focus:ring-2 focus:ring-inset focus:ring-brand-purple",
                      formData.country_of_operation === "" ? "text-slate-400" : "text-slate-900",
                    )}
                    value={formData.country_of_operation}
                    onChange={onFieldChangeHandler}
                    required
                  >
                    <option value="" disabled>
                      Select a country of operation
                    </option>
                    {COUNTRIES.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {error && <Alert message={error} level="failure" />}
            <button
              type="submit"
              className="w-[18rem] place-self-center justify-center inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-sm md:text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              disabled={
                !formData.business_name.length ||
                !formData.description.length ||
                !formData.country_of_operation ||
                isGeneratingReport
              }
            >
              {isGeneratingReport ? (
                <>
                  <CircularProgress color="inherit" size="1.25rem" className="-ml-2 animate-spin text-white h-5 w-5" />
                  Generating Report...
                </>
              ) : (
                <>
                  <AutoAwesome className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Generate Compliance Report
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }

  const businessBasicProfileCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id == BUSINESS_PROFILE_CHECK_ID,
  );

  const webPresenceCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === WEB_PRESENCE_CHECK_ID,
  );
  const addressesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADDRESS_CHECK_ID,
  );
  const highRiskCountriesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_COUNTRY_CHECK_ID,
  );

  const highRiskIndustriesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_CHECK_ID,
  );

  const highRiskIndustriesV2CheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  );

  const adverseMediaCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADVERSE_MEDIA_CHECK_ID,
  );
  const sanctionsWatchlistCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SANCTIONS_WATCHLIST_CHECK_ID,
  );
  const policiesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === POLICY_CHECK_ID,
  );
  const mccCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === MCC_CHECK_ID,
  );
  const businessOwnersCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERS_CHECK_ID,
  );

  /**
   * Document Checks Start
   */
  const incorporationDocumentCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === INCORPORATION_DOCUMENT_CHECK_ID,
  );
  const businessOwnershipCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERSHIP_CHECK_ID,
  );
  const sourceOfFundsCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SOURCE_OF_FUNDS_CHECK_ID,
  );
  const proofOfAddressCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === PROOF_OF_ADDRESS_CHECK_ID,
  );
  const cannabisLicenseCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === CANNABIS_LICENSE_CHECK_ID,
  );
  const einDocumentCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === EIN_DOCUMENT_CHECK_ID,
  );
  /**
   * Document Checks End
   * */
  const documentCheckResults = [
    incorporationDocumentCheckResult,
    businessOwnershipCheckResult,
    sourceOfFundsCheckResult,
    proofOfAddressCheckResult,
    cannabisLicenseCheckResult,
    einDocumentCheckResult,
  ].filter(Boolean);

  const currentStatusMessage = webPresenceCheckResult?.status_messages
    .filter(
      (msg: { event: string; content: { status: string } }) =>
        msg.event === "status" && msg.content.status !== "Loading: WebPresence Data Loader...",
    )
    .pop();

  const getJobName = () => {
    if (!job?.input_payload?.self_attested_data) return "";

    return (
      job.input_payload?.self_attested_data?.name ||
      job.input_payload?.self_attested_data?.business_name ||
      job.input_payload?.self_attested_data?.registered_business_name ||
      job.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "") ||
      `${job.input_payload?.self_attested_data?.first_name}${
        job.input_payload?.self_attested_data?.middle_name
          ? ` ${job.input_payload?.self_attested_data?.middle_name} `
          : " "
      }${job.input_payload?.self_attested_data?.last_name}`
    );
  };

  if (!job || !agentInstanceId)
    return (
      <div className="md:px-[2.5rem] py-10 flex flex-col gap-y-6 h-full max-w-[1120px]">
        <div className="bg-indigo-100 pr-10 pl-6 pt-6 rounded-xl flex flex-col gap-y-6 w-full">
          <img src={ParchaLogo} alt="Parcha Logo" className="object-cover self-start md:h-7 h-4" />
          <div className="flex flex-col lg:flex-row gap-x-4 justify-between h-full">
            <div className="flex flex-col gap-y-5">
              <h1 className="text-xl md:text-2xl font-bold">Your report is being generated...</h1>
              <div className="text-sm md:text-base text-slate-700">
                <p>Give us a minute or two while Parcha works our magic.</p>
                <p>We will email you when the report is completed as well.</p>
              </div>
            </div>
            <img
              src={BusinessDueDiligenceImage}
              alt="Parcha Logo"
              className="object-fit h-[8.5rem] w-[10rem] md:h-[12.79669rem] md:w-[15rem]"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-[40px] w-full">
          <WebsiteCardSkeleton />
          <BusinessBasicProfile
            checkResult={businessBasicProfileCheckResult}
            input={job.input_payload}
            sourcesDictionary={sourcesDictionary}
            activeSource={activeSource}
            setActiveSource={setActiveSource}
            handleSourceClick={handleSourceClick}
          />
        </div>
        <div className="flex flex-col gap-y-4 h-full">
          <span className={shimmerEffectClassNames}>Business Description</span>
          <div className="animate-pulse flex flex-col gap-y-2">
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 h-full">
          <span className={shimmerEffectClassNames}>Products and Services</span>
          <div className="animate-pulse flex flex-col gap-y-2">
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-0 h-0"></div>
            <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            <div className="ml-2 w-1/5 h-3 bg-slate-200 rounded-lg"></div>
            <div className="ml-2 w-1/3 h-3 bg-slate-200 rounded-lg"></div>
          </div>
        </div>
      </div>
    );

  const officialWebsite =
    webPresenceCheckResult?.verified_data?.official_websites?.length > 0
      ? webPresenceCheckResult?.verified_data?.official_websites?.[0]
      : null;
  const websiteUrl =
    officialWebsite?.scanned_website?.webpage_url || webPresenceCheckResult?.check_result?.official_website_match?.url;
  const websiteTitle =
    officialWebsite?.scanned_website?.webpage_title ||
    webPresenceCheckResult?.check_result?.official_website_match?.title;
  const websiteScreenshotUrl =
    officialWebsite?.scanned_website?.screenshot_url ||
    webPresenceCheckResult?.check_result?.official_website_match?.screenshot_url;
  const websiteVisualSummary =
    officialWebsite?.analysis?.extracted_metadata?.visual_summary ||
    webPresenceCheckResult?.check_result?.official_website_match?.visual_summary;
  const scrapeType = officialWebsite?.scanned_website?.scrape_type;

  return (
    <div className="md:px-[2.5rem] px-4 py-4 md:py-10 h-full flex flex-col gap-y-6 md:max-w-[1120px]">
      {webPresenceCheckResult?.verified_data?.business_profile?.profile_confidence === "LOW" && (
        <LowProfileConfidenceBanner />
      )}
      {webPresenceCheckResult?.status === "complete" &&
        webPresenceCheckResult?.verified_data?.only_used_extended_search_results && (
          <div className="flex items-center justify-center gap-x-5 py-5 bg-indigo-100 text-slate-700 rounded-lg">
            <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
            <span className="font-semibold">
              {website
                ? "We used an expanded web search because the provided business website was unreachable."
                : "We used an expanded web search because a business website was not provided."}
            </span>
          </div>
        )}
      {["in progress", "queued"].includes(job.status) && (
        <div className="flex bg-indigo-100 pr-10 pl-6 py-6 lg:pt-6 sm:pb-0 rounded-xl w-full gap-6 justify-between items-end">
          <div className="flex flex-col gap-y-6 ">
            <img src={ParchaLogo} alt="Parcha Logo" className="object-cover self-start md:h-7 h-5" />
            <div className="flex flex-row gap-x-4 justify-between h-full">
              <div className="flex flex-col gap-y-5">
                <h1 className="text-xl md:text-2xl font-bold">Your report is being generated...</h1>

                <div className="text-sm md:text-base text-slate-700">
                  <p>Give us a couple of minutes while Parcha works our magic.</p>
                  <p>We will email you when the report is completed as well.</p>
                </div>
              </div>
            </div>

            <div className="sm:pb-6">
              {currentStatusMessage &&
                webPresenceCheckResult?.verified_data?.business_profile?.profile_status !== "completed" &&
                currentStatusMessage.content && <CheckStatusMessage messageContents={currentStatusMessage.content} />}
            </div>
          </div>
          <img
            src={BusinessDueDiligenceImage}
            alt="Parcha Logo"
            className="hidden sm:block object-fit h-[8.5rem] w-[10rem] md:h-[12.79669rem] md:w-[15rem]"
          />
        </div>
      )}
      <div className="flex flex-col gap-8 px-6 md:p-0">
        {(webPresenceCheckResult || businessBasicProfileCheckResult) && (
          <div className="flex flex-col lg:flex-row gap-[40px] w-full">
            {websiteUrl ? (
              <motion.div
                initial={{ opacity: 0, filter: "blur(10px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                transition={{ duration: disableAnimation ? 0 : 1 }}
                className="w-full lg:w-fit mx-auto md:m-0"
              >
                {scrapeType === "instagram" ? (
                  <InstagramWebsiteCard
                    biography={officialWebsite?.scanned_website?.webpage_structured_scrape?.biography}
                    username={officialWebsite?.scanned_website?.webpage_structured_scrape?.username}
                    businessCategoryName={
                      officialWebsite?.scanned_website?.webpage_structured_scrape?.businessCategoryName
                    }
                    logoUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.profilePicUrlHD}
                    name={officialWebsite?.scanned_website?.webpage_structured_scrape?.fullName}
                    followersCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followersCount}
                    instagramUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.url}
                    externalUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.externalUrl}
                    latestPosts={officialWebsite?.scanned_website?.webpage_structured_scrape?.latestPosts}
                    isVerified={officialWebsite?.scanned_website?.webpage_structured_scrape?.verified}
                    postsCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.postsCount}
                    followCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followsCount}
                  />
                ) : (
                  <WebsiteCard
                    websiteUrl={websiteUrl}
                    websiteLabel={websiteTitle}
                    screenshotUrl={websiteScreenshotUrl}
                    visualSummary={websiteVisualSummary}
                  />
                )}
              </motion.div>
            ) : webPresenceCheckResult ? (
              <WebsiteCardSkeleton />
            ) : null}
            {businessBasicProfileCheckResult && (
              <div className="flex-1">
                <BusinessBasicProfile
                  checkResult={businessBasicProfileCheckResult}
                  input={job.input_payload}
                  sourcesDictionary={sourcesDictionary}
                  activeSource={activeSource}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        )}
        {webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 ||
        businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
          <FindingsSection
            title="Business Description"
            content={
              <div className="flex flex-col gap-y-3">
                {webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 ? (
                  webPresenceCheckResult.verified_data.business_profile.business_description.map(
                    (descriptionEntry: { description: string; sources?: { source_id: number }[] }, index: number) => (
                      <div key={index}>
                        <AnimatedParagraph text={descriptionEntry.description} disableAnimation={disableAnimation} />
                        <span className="inline-flex gap-1 ml-2">
                          {descriptionEntry.sources?.map((s) => (
                            <SourceTagCircle
                              key={s.source_id}
                              source={sourcesDictionary[s.source_id]}
                              isActive={s.source_id === activeSource}
                              setActiveSource={setActiveSource}
                              handleSourceClick={handleSourceClick}
                            />
                          ))}
                        </span>
                      </div>
                    ),
                  )
                ) : businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
                  <div>
                    <AnimatedParagraph
                      text={businessBasicProfileCheckResult.verified_data.business_description_found.description}
                      disableAnimation={disableAnimation}
                    />
                    <span className="inline-flex gap-1 ml-2">
                      {businessBasicProfileCheckResult.verified_data.business_description_found.sources?.map((s) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ))}
                    </span>
                  </div>
                ) : null}
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Business Description</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}
        {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_summary?.length > 0 ||
        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_list?.length ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
                          ?.products_and_services_summary
                      }
                      disableAnimation={disableAnimation}
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services?.sources?.map(
                      (s: { source_id: string }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
                <ul className="list-disc list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services.products_and_services_list?.map(
                    (product: { name: string; description: string }, index: number) => (
                      <li key={index}>
                        <span className="font-semibold">
                          <AnimatedParagraph
                            text={product.name}
                            className="inline"
                            disableAnimation={disableAnimation}
                          />
                        </span>
                        {" - "}
                        <span>
                          <AnimatedParagraph
                            text={product.description}
                            className="inline"
                            disableAnimation={disableAnimation}
                          />
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : businessBasicProfileCheckResult?.verified_data?.business_products_and_services_found ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        businessBasicProfileCheckResult.verified_data.business_products_and_services_found
                          .products_and_services
                      }
                      disableAnimation={disableAnimation}
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {businessBasicProfileCheckResult.verified_data.business_products_and_services_found.sources?.map(
                      (s: { source_id: string }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
              </div>
            }
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4 h-full">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Products and Services</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-0 h-0"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}

        {webPresenceCheckResult?.verified_data?.business_profile?.business_customers?.length > 0 ? (
          <FindingsSection
            title="Current Customers"
            content={
              <div>
                <ol className="list-decimal list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult.verified_data.business_profile.business_customers.map(
                    (customer: string, index: Key | null | undefined) => (
                      <li key={index}>
                        <AnimatedParagraph text={customer} className="inline" disableAnimation={disableAnimation} />
                      </li>
                    ),
                  )}
                </ol>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : null}
        {businessBasicProfileCheckResult && (
          <OnlinePresence checkResult={businessBasicProfileCheckResult} sourcesDictionary={sourcesDictionary} />
        )}
        {addressesCheckResult && (
          <Addresses
            checkResult={addressesCheckResult}
            sourcesDictionary={sourcesDictionary}
            activeSource={activeSource}
            setActiveSource={setActiveSource}
            handleSourceClick={handleSourceClick}
          />
        )}
        {highRiskCountriesCheckResult || highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult ? (
          <div className="flex-1 flex flex-col lg:flex-row gap-5 justify-between last:pb-5">
            {highRiskCountriesCheckResult && (
              <div className="w-full lg:w-1/2">
                <HighRiskCountries
                  checkResult={highRiskCountriesCheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}

            {(highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult) && (
              <div className="w-full lg:w-1/2">
                <HighRiskIndustries
                  checkResult={highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        ) : null}

        {mccCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <MCC
              checkResult={mccCheckResult}
              sourcesDictionary={sourcesDictionary}
              setActiveSource={setActiveSource}
              activeSource={activeSource}
              handleSourceClick={handleSourceClick}
            />
          </div>
        )}

        {(adverseMediaCheckResult || sanctionsWatchlistCheckResult) && (
          <div className="flex-1 last:pb-[5rem]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {adverseMediaCheckResult && (
                <div className="w-full">
                  <AdverseMedia checkResult={adverseMediaCheckResult} />
                </div>
              )}

              {sanctionsWatchlistCheckResult && (
                <div className="w-full">
                  <SanctionsWatchlist checkResult={sanctionsWatchlistCheckResult} />
                </div>
              )}
            </div>
          </div>
        )}

        {policiesCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <Policies
              checkResult={policiesCheckResult}
              sourcesDictionary={sourcesDictionary}
              activeSource={activeSource}
              setActiveSource={setActiveSource}
              handleSourceClick={handleSourceClick}
            />
          </div>
        )}

        {businessOwnersCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <BusinessOwners checkResult={businessOwnersCheckResult} sourcesDictionary={sourcesDictionary} />
          </div>
        )}

        {job && job.status === "complete" && job.agent_id === "public-bdd" && (
          <>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <WorkspacePremiumRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Registration & Licenses</span>
                </div>
                <div>
                  <UpsellSectionWrapper
                    title="Verify legal compliance"
                    subtitle="Ensure proper registration and licensing globally."
                    description="Get a comprehensive report on the legal compliance of your business. Ensure that your business is
          properly registered and licensed globally."
                    learnMoreURL="https://www.parcha.com/products/business-validation"
                    imgSrc={LegalBusinessEntitiesImg}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <GroupRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Business Owners</span>
                </div>
                <UpsellSectionWrapper
                  title="Comprehensive owner vetting"
                  subtitle="Ensure regulatory compliance and mitigate risks."
                  description="Examine the personal and professional backgrounds, financial records, and legal standing to ensure they meet regulatory requirements."
                  learnMoreURL="https://www.parcha.com/products/ownership-documents"
                  imgSrc={BusinessOwnersImg}
                />
              </div>
            </div>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <TaskRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Document Verificaion</span>
                </div>
                <UpsellSectionWrapper
                  title="Multilingual document analysis"
                  subtitle="Extract crucial business data instantly, globally."
                  description="Extract information from documents in 60+ languages identifying and validating the business name, incorporation date, business owners, EIN, source of funds and more."
                  learnMoreURL="https://www.parcha.com/products/incorporation-documents"
                  imgSrc={IncorporationDocsImg}
                />
              </div>
            </div>
          </>
        )}

        <DocumentList
          input={job.input_payload}
          endpointUrl={endpointUrl}
          documentCheckResults={documentCheckResults}
          setSelectedDocumentCheck={setSelectedDocumentCheck}
        />

        {selectedDocumentCheck && (
          <Transition.Root show={Boolean(selectedDocumentCheck)} as={Fragment} appear>
            <Dialog as="div" className="relative z-20" onClose={() => setSelectedDocumentCheck(null)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-white bg-opacity-100 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-20 w-full h-full overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full h-full transition-all">
                    <div className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200">
                      <div className="flex items-center justify-between gap-x-2 text-slate-900">
                        <PlagiarismRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <h2 className="font-semibold">Document Validation</h2>
                        <div className="flex items-center gap-x-2 ml-5">
                          {agent?.agentType === "kyb" ? (
                            <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          ) : (
                            <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          )}
                        </div>
                        <span>{getJobName()}</span>
                      </div>
                      <button
                        className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                        onClick={() => setSelectedDocumentCheck(null)}
                      >
                        <CloseRounded sx={{ fontSize: "1rem" }} className="mr-1" />
                        <span>Close</span>
                      </button>
                    </div>
                    <DocumentValidation
                      agentInstanceId={selectedDocumentCheck.agent_instance_id}
                      jobId={job.id}
                      caseId={job.input_payload.id}
                      endpointUrl={endpointUrl}
                      documentUrl={`${window.location.protocol}//${endpointUrl}/getDocument?case_id=${job.input_payload.id}&expired_url=${selectedDocumentCheck.documentUrl}`}
                      documentName={selectedDocumentCheck.documentTitle}
                      title={getJobName()}
                      agentType="kyb"
                      checkId={selectedDocumentCheck.command_id}
                      checkName={selectedDocumentCheck.command_name}
                      onCloseHandler={() => setSelectedDocumentCheck(null)}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        )}

        {sourcesValues.length > 0 && sourcesValues.every((source) => !("text" in source)) && (
          <div className="flex flex-col gap-y-3 w-full">
            <div className="flex items-center gap-x-2">
              <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
              <span className="text-lg font-semibold">Sources</span>
              <button
                onClick={toggleShowingSources}
                aria-label={showingSources ? "Hide Sources" : "Show Sources"}
                className="cursor-pointer w-4 h-4 flex items-center justify-center rounded-full hover:bg-slate-100 hover:text-brand-purple"
              >
                {showingSources ? (
                  <ExpandLessRounded sx={{ fontSize: "1rem" }} />
                ) : (
                  <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
                )}
              </button>
            </div>
            {showingSources &&
              sourcesValues.map((source, index) => {
                return <FindingsSource source={{ ...source, id: index + 1 }} key={source.source_id} />;
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const WebsiteCardSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-1 w-full md:w-[520px] bg-[#F8FAFC]">
      <div className="w-full h-[13rem] md:h-[18.4rem] flex flex-col gap-y-2 items-center justify-center rounded-lg">
        <ImageSearch sx={{ fontSize: "2rem" }} className="text-slate-300" />
        <span className={shimmerEffectClassNames}>Website Screenshot</span>
      </div>
    </div>
  );
};

interface OnlinePresenceProps {
  checkResult: any;
  sourcesDictionary: { [key: string]: any };
}

const OnlinePresence: React.FC<OnlinePresenceProps> = ({ checkResult, sourcesDictionary }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Online Presence
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  if (!checkResult?.verified_data || !sourcesDictionary) {
    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}

        <GenericSkeleton />
      </div>
    );
  }

  const allWebsites = Object.values(sourcesDictionary).reduce((acc, source) => {
    if (
      source?.structured_data &&
      ["instagram", "twitter", "linkedin_company", "facebook", "crunchbase"].includes(
        source.structured_data.scrape_type,
      )
    ) {
      const existingIndex = acc.findIndex((profile) => profile.url === source.url);
      if (existingIndex === -1) {
        acc.push(source);
      } else if (Object.keys(source.structured_data).length > Object.keys(acc[existingIndex].structured_data).length) {
        acc[existingIndex] = source;
      }
    }
    return acc;
  }, []);

  let finalWebsites = [];
  for (const website of allWebsites) {
    if (
      checkResult?.verified_data?.social_media_source_ids?.includes(website.source_id) ||
      checkResult?.verified_data?.business_profile_source_ids?.includes(website.source_id)
    ) {
      finalWebsites.push(website);
    }
    if (
      checkResult?.verified_data?.social_media_urls?.includes(website.url) ||
      checkResult?.verified_data?.business_profile_urls?.includes(website.url)
    ) {
      finalWebsites.push(website);
    }
  }

  // Remove duplicate websites based on URL
  finalWebsites = finalWebsites.filter(
    (website, index, self) => index === self.findIndex((t) => t.url === website.url),
  );

  if (finalWebsites.length === 0) {
    return null;
  }

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-4">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      <div className="h-fit columns-1 md:columns-2 lg:columns-3 gap-4">
        {finalWebsites.map(
          (
            source: {
              structured_data: {
                scrape_type: string | null;
                name: string | null;
                description: string | null;
                tagline: string | null;
                websiteUrl: string | null;
                industry: string | null;
                url: string | null;
                employeeCount: string | null;
                headquater: { city: any; country: any };
                avatar: string | null;
                about_me: { text: string | null };
                followers: string | number | null;
                facebookUrl: string | null;
                title: string | null;
                categories: any[];
                profilePicture: string | null;
                twitterUrl: string | null;
                userName: string | null;
                biography: string | null;
                businessCategoryName: string | null;
                externalUrl: string | null;
                followersCount: number | null;
                profilePicUrlHD: string | null;
                fullName: string | null;
                latestPosts: { id: string; displayUrl: string | null; url: string | null }[] | null | undefined;
                webpage_metadata: { domain: string };
                webpage_url: string | URL;
                webpage_title: string;
                screenshot_url: any;
                short_description: string | null;
                funding_total: { value: number; currency: string; value_usd: number } | null;
                num_funding_rounds: number | null;
                last_funding_type: string | null;
                last_funding_at: string | null;
                current_advisors_image_list: Array<{
                  person_identifier: {
                    value: string;
                    image_id: string | null;
                  };
                  job_type: string;
                }> | null;
                funding_rounds_list: Array<{
                  announced_on: string;
                  num_investors: number;
                  money_raised: { value: number; currency: string; value_usd: number };
                  lead_investor_identifiers: Array<{ value: string }>;
                }> | null;
                investors_list: Array<{
                  is_lead_investor: boolean | null;
                  investor_identifier: { value: string };
                  partner_identifiers: Array<{ value: string }>;
                }> | null;
                social_fields: {
                  facebook: { value: string } | null;
                  linkedin: { value: string } | null;
                  twitter: { value: string } | null;
                } | null;
              };
              url: string | null;
            },
            index: Key | null | undefined,
          ) => {
            const presence = source.structured_data;
            switch (presence.scrape_type) {
              case "linkedin_company":
                return (
                  <div className="mb-4" key={index}>
                    <LinkedInCompanyCard
                      name={presence.name}
                      description={presence.description}
                      overview={presence.tagline}
                      url={presence.websiteUrl}
                      industry={presence.industry}
                      linkedInUrl={presence.url}
                      employeesCount={presence.employeeCount}
                      location={
                        presence.headquater ? `${presence.headquater?.city} ${presence.headquater?.country}` : null
                      }
                      logoUrl={presence.avatar}
                    />
                  </div>
                );
              case "facebook":
                return presence?.title ? (
                  <div className="mb-4" key={index}>
                    <FacebookProfileCard
                      aboutMe={presence.about_me?.text}
                      followersCount={presence.followers?.toString() || null}
                      facebookUrl={presence.facebookUrl}
                      name={presence.title}
                      industry={presence.categories?.join(", ")}
                    />
                  </div>
                ) : null;
              case "twitter":
                return (
                  <div className="mb-4" key={index}>
                    <XTwitterProfileCard
                      description={presence.description}
                      followers={
                        typeof presence.followers === "string" ? parseInt(presence.followers) : presence.followers
                      }
                      name={presence.name}
                      profilePicture={presence.profilePicture}
                      twitterUrl={presence.twitterUrl}
                      userName={presence.userName}
                    />
                  </div>
                );
              case "instagram":
                return (
                  <div className="mb-4" key={index}>
                    <InstagramProfileCard
                      biography={presence.biography}
                      businessCategoryName={presence.businessCategoryName}
                      externalUrl={presence.externalUrl}
                      followers={presence.followersCount}
                      instagramUrl={presence.url}
                      logoUrl={presence.profilePicUrlHD}
                      name={presence.fullName}
                      latestPosts={presence.latestPosts}
                    />
                  </div>
                );
              case "crunchbase":
                return <CrunchbaseCompanyCard key={index} {...{ ...presence, url: source.url }} />;
            }

            return (
              <div className="mb-4" key={index}>
                <WebPresenceCard
                  title={presence.webpage_title}
                  url={presence.webpage_url}
                  imageUrl={presence.screenshot_url}
                  domain={presence.webpage_metadata?.domain}
                  avatarUrl={presence.avatar}
                />
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

interface HighRiskIndustriesProps {
  checkResult: any;
  activeSource: number | null;
  sourcesDictionary: any;
  setActiveSource: (source: number) => void;
  handleSourceClick: (source: number) => void;
}

const HighRiskIndustries: React.FC<HighRiskIndustriesProps> = ({
  checkResult,
  activeSource,
  sourcesDictionary,
  setActiveSource,
  handleSourceClick,
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <FactoryRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Industry Classifications
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  const filteredStatusMessages =
    checkResult?.status_messages?.filter(
      (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
    ) ?? [];
  const lastStatusMessage =
    filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

  if (!checkResult?.verified_data?.found_industries) {
    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.found_industries || {}).map(([_, industry]) => ({
    found: industry?.industry_code?.code + " - " + industry?.industry_code?.title,
    evidence: industry?.evidence,
  }));

  const industryMatches =
    checkResult.check_result?.industry_matches ||
    checkResult.check_result?.high_risk_industries_match?.high_risk_industries?.map((industry) => ({
      high_risk_category: industry,
      is_high_risk: true,
    })) ||
    [];

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" ? (
        evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
            {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
            <GenericSkeleton />
          </div>
        )
      ) : industryMatches?.length > 0 ? (
        <div className="flex flex-col gap-2">
          {industryMatches.map((industry, index) => (
            <IndustryCard
              key={index}
              industry={industry}
              sourcesDictionary={sourcesDictionary}
              activeSource={activeSource}
              setActiveSource={setActiveSource}
              handleSourceClick={handleSourceClick}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const IndustryCard: React.FC<IndustryCardProps> = ({
  industry,
  sourcesDictionary,
  activeSource,
  setActiveSource,
  handleSourceClick,
}) => {
  const isHighRisk = industry?.is_high_risk;
  const highRiskCategory = industry?.high_risk_category;

  return (
    <div className="rounded-lg p-3 flex flex-col gap-y-1 bg-slate-50">
      <div className="flex items-baseline gap-x-2">
        <div className="flex-shrink-0">
          <WrappedValue text={industry.naics_code?.code} level="normal" />
        </div>
        <div className="flex flex-col">
          {industry.naics_code && (
            <h4 className="text-sm font-semibold inline-flex items-baseline gap-x-2">
              {industry.naics_code.title}
              <div className="flex gap-1">
                {industry.source_ids.map((sourceId) => {
                  const source = sourcesDictionary[sourceId];
                  return (
                    <SourceTagCircle
                      source={source}
                      isActive={source === activeSource}
                      setActiveSource={setActiveSource}
                      handleSourceClick={handleSourceClick}
                    />
                  );
                })}
              </div>
            </h4>
          )}

          {isHighRisk && (
            <div className="flex items-center gap-x-2 mt-1">
              <SubdirectoryArrowRightRounded sx={{ fontSize: "1rem" }} className="text-gray-500" />
              <span className="text-sm font-semibold text-yellow-700">{highRiskCategory}</span>
              <Tooltip title={industry.high_risk_evidence} arrow placement="top">
                <div>
                  <WrappedValue
                    text={
                      <div className="flex items-center gap-x-0.5">
                        <WarningRounded sx={{ fontSize: "0.875rem" }} />
                        <span className="font-bold">High Risk</span>
                      </div>
                    }
                    level="caution"
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AdverseMedia = ({ checkResult }) => {
  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        <div className="flex items-center gap-x-2">
          <NewspaperRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className={twJoin("text-xl font-semibold", shimmerEffectClassNames)}>Adverse Media Screening</span>
        </div>
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.adverse_media_hits || {}).map(([_, media]) => ({
    found:
      media?.article_metadata.who?.perpetrators?.length > 0
        ? media.article_metadata.who.perpetrators[0]?.business_name
        : "",
    evidence: media?.article_metadata?.what,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <NewspaperRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Adverse Media Screening</span>
        {checkResult.passed && (
          <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
        {checkResult.status === "running" && evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : checkResult.check_result ? (
          <div className="grid grid-cols-1 gap-x-3 gap-y-2">
            {checkResult.check_result.verified_adverse_media_hits?.length > 0 ? (
              checkResult.check_result.verified_adverse_media_hits?.map((hit, index) => (
                <AdverseMediaHit adverseMediaHit={{ ...hit, type: "kyb" }} level={"failure"} key={index} />
              ))
            ) : (
              <div className="p-3 bg-slate-50 text-slate-500 text-sm">No strong matches found</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SanctionsWatchlist = ({ checkResult }) => {
  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        <div className="flex items-center gap-x-2">
          <VisibilityRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className={twJoin("text-xl font-semibold", shimmerEffectClassNames)}>
            Sanctions Watchlist Screening
          </span>
        </div>
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.sanctions_data || {}).map(([_, hit]) => ({
    found: hit?.names[0],
    evidence: hit?.sanction_details,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <VisibilityRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Sanctions Watchlist Screening</span>
        {checkResult?.passed && (
          <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}

        {checkResult.status === "running" && evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : checkResult.check_result ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
            {checkResult.check_result?.verified_sanctions_hits?.length > 0 ? (
              checkResult.check_result?.verified_sanctions_hits?.map(
                (
                  sanctionHit: {
                    matched_lists: string | any[];
                    source_url: string;
                    matched_names: string[] | null | undefined;
                    description: string | null | undefined;
                  },
                  index: Key | null | undefined,
                ) => (
                  <SanctionsWatchlistCard
                    key={index}
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0] : null}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                ),
              )
            ) : (
              <div className=" col-span-2 p-3 bg-slate-50 text-slate-500 text-sm">No strong matches found</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface PoliciesProps {
  checkResult: any;
  activeSource: any;
  sourcesDictionary: any;
  setActiveSource: any;
  handleSourceClick: any;
}

const Policies: React.FC<PoliciesProps> = ({
  checkResult,
  activeSource,
  sourcesDictionary,
  setActiveSource,
  handleSourceClick,
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PolicyRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Policies
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.found_policies || {}).map(([_, policy]) => ({
    found: policy?.policy_name,
    evidence: policy?.evidence,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" && evidenceList.length > 0 ? (
        <div className="h-[200px]">
          <SmartSkeleton evidenceList={evidenceList} />
        </div>
      ) : checkResult.check_result ? (
        <div className="flex flex-col gap-y-2">
          {checkResult.check_result.policies_found?.map((policy, index) => {
            const source = sourcesDictionary[policy.source_ids[0]];

            return (
              <WebsitePolicyCard
                key={index}
                match_type={policy.policy_type}
                explanation={policy.explanation}
                url={source?.url}
                sourceComponent={
                  <SourceTagCircle
                    source={source}
                    isActive={source === activeSource}
                    setActiveSource={setActiveSource}
                    handleSourceClick={handleSourceClick}
                  />
                }
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

interface MCCProps {
  checkResult: {
    verified_data?: any;
    passed?: boolean;
    answer?: string;
    check_result?: {
      mcc_code?: string;
      mcc_code_description?: string;
      products_sold?: Array<any>;
    };
  };
}

const MCC: React.FC<MCCProps> = ({ checkResult }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <Pin sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data || !checkResult?.verified_data
            ? shimmerEffectClassNames
            : "",
        )}
      >
        Merchant Categorization (MCC)
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );
  const MCCSkeleton = (
    <div className="animate-pulse flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2">
        <div className="w-[5rem] px-3 py-1.5 h-[1.5rem] bg-slate-200 rounded-lg">
          <div className="w-full h-full bg-slate-300 rounded-lg"></div>
        </div>
        <div className="flex flex-col gap-y-2 w-full">
          <div className="w-3/4 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
        </div>
      </div>
      <div className="w-1/5 h-3 bg-slate-300 rounded-lg"></div>
      <div className="flex flex-col gap-y-2 w-full">
        <div className="w-3/4 h-3 bg-slate-200 rounded-lg"></div>
        <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
      </div>
      <div className="w-1/6 h-3 bg-slate-300 rounded-lg"></div>
      <table className="w-full border-collapse animate-pulse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">
              <div className="w-1/5 h-3 bg-slate-300 rounded-lg"></div>
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              <div className="w-1/4 h-3 bg-slate-300 rounded-lg"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(3)].map((_, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td className="border border-gray-300 px-4 py-2">
                <div className="h-4 bg-slate-200 rounded"></div>
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <div className="h-4 bg-slate-200 rounded"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];

    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        {MCCSkeleton}
      </div>
    );
  }

  const level = checkResult.passed ? "success" : "failure";

  const evidenceList = [];
  checkResult.verified_data.candidate_mcc_codes?.forEach(
    (mccCode: { mcc_code: string; mcc_code_description: string }) => {
      evidenceList.push({
        found: mccCode.mcc_code,
        evidence: mccCode.mcc_code_description,
      });
    },
  );

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <Pin sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Merchant Categorization (MCC)</span>
      </div>
      {checkResult.passed !== null && !checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" ? (
        <div className="h-[200px]">
          {evidenceList.length > 0 ? <SmartSkeleton evidenceList={evidenceList} /> : <MCCSkeleton />}
        </div>
      ) : (
        <div className="grid  gap-x-3 gap-y-2">
          <div className="flex flex-col gap-4 py-5 px-4">
            <div className="flex flex-row items-center gap-2">
              {checkResult.check_result?.mcc_code && (
                <div className="flex-shrink-0">
                  <WrappedValue
                    text={
                      <div className="flex gap-x-1 items-center">
                        {level === "success" ? (
                          <CheckRounded sx={{ fontSize: "1rem" }} />
                        ) : level === "failure" ? (
                          <CancelRounded sx={{ fontSize: "1rem" }} />
                        ) : null}
                        <span>{checkResult.check_result?.mcc_code}</span>
                      </div>
                    }
                    level={level}
                  />
                </div>
              )}
              {checkResult.check_result?.mcc_code_description && (
                <div className="break-words">{checkResult.check_result.mcc_code_description}</div>
              )}
            </div>

            {checkResult.check_result?.mcc_code_description && (
              <div className="flex flex-col gap-y-2">
                <div className="font-medium">Description:</div>
                <div>{checkResult.check_result.mcc_code_description}</div>
              </div>
            )}

            {checkResult.check_result?.products_sold && checkResult.check_result?.products_sold.length > 0 && (
              <div className="flex flex-col gap-y-2">
                <div className="font-medium">Products Sold:</div>
                {typeof checkResult.check_result.products_sold[0] === "object" ? (
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2 text-left">Product</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(
                        checkResult.check_result?.products_sold.reduce<Record<string, string>>((acc, product) => {
                          if (!acc[product.product]) {
                            acc[product.product] = product.description;
                          }
                          return acc;
                        }, {}),
                      ).map(([product, description], index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                          <td className="border border-gray-300 px-4 py-2">{product}</td>
                          <td className="border border-gray-300 px-4 py-2">{description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <ul className="list list-disc list-outside ml-8 mt-2 space-y-2">
                    {checkResult.check_result.products_sold.map((product, index) => (
                      <li key={index} className="list-item">
                        {product}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const BusinessOwners = ({ checkResult, sourcesDictionary }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PeopleRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Business Owners
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  if (!checkResult?.check_result && ["running", "pending"].includes(checkResult?.status)) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }
  const dedupedBusinessOwners = checkResult.check_result
    ? Array.from(
        new Map(
          checkResult.check_result.business_owner_matches
            ?.map((owner) => [owner.full_name_as_spelled_in_input || owner.name, owner])
            .filter(([key, owner], index, arr) =>
              arr.some(([_, o]) => o.found_in_business_owners_content) ? owner.found_in_business_owners_content : true,
            ),
        ).values(),
      )
    : [];

  return checkResult.check_result ? (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {Header}
      {checkResult.answer && <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
        {dedupedBusinessOwners.map((owner, index) => (
          <BusinessOwnerEntry key={index} data={owner} source={sourcesDictionary[owner.source_ids[0]]} />
        ))}
      </div>
    </div>
  ) : null;
};

const BusinessOwnerEntry = ({ data, source }) => {
  return (
    <div
      className={`p-5 truncate text-slate-700 flex flex-col gap-y-5 bg-slate-50 rounded-lg ${
        "found_in_business_owner_content" in data
          ? data.found_in_business_owners_content
            ? "border-2 border-green-500 bg-white"
            : "border-2 border-red-500 bg-white"
          : ""
      }`}
    >
      <div className="flex flex-col">
        <span className="text-base font-semibold">{data.full_name_as_spelled_in_input || data.name}</span>
        {"full_name_as_spelled_in_input" in data &&
          data.name !== data.full_name_as_spelled_in_input &&
          data.found_in_business_owners_content && <span className="text-sm italic">found as '{data.name}'</span>}
        {(data.title || data.business_name) && (
          <span className="text-sm">
            {data.title}
            {data.business_name ? ` at ${data.business_name}` : null}
          </span>
        )}
        {source && (
          <div className="mt-3 p-4 bg-white rounded-lg shadow-sm">
            <h3 className="font-semibold text-base mb-2 break-words overflow-hidden">
              <a
                href={
                  (source.url || source.structured_data?.url)?.startsWith("http")
                    ? source.url || source.structured_data?.url
                    : `https://${source.url || source.structured_data?.url}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {source.title || source.structured_data?.fullName || "@" + source.structured_data?.userName}
              </a>
            </h3>
            <p className="text-sm mb-3 break-words whitespace-normal italic">
              {source.summary?.length > 150 ? `${source.summary.slice(0, 150)}...` : source.summary}
            </p>
            {/* {source.screenshot_url && (
              <div className="mt-3">
                <img src={source.screenshot_url} alt="Source Screenshot" className="w-full h-auto rounded-md" />
              </div>
            )} */}
          </div>
        )}
      </div>
      {/* {sourceData && (
        <div className="flex items-center gap-x-2">
          {doc?.document_metadata?.document_type === "li_profiles" && (
            <>
              <img src={linkedInLogoIcon} alt="LinkedIn Logo" className="w-4 h-4" />
              <a
                href={doc.document_metadata.url}
                target="_blank"
                rel="noreferrer"
                className="truncate text-brand-purple"
              >
                {doc.document_metadata.url}
              </a>
            </>
          )}
          {doc?.document_metadata?.url && (
            <a href={doc.document_metadata.url} target="_blank" rel="noreferrer" className="truncate text-brand-purple">
              {doc.document_metadata.url}
            </a>
          )}
        </div>
      )}
      {parsedData && (
        <>
          {parsedData.EXPERIENCE.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <span className="text-xs font-semibold">Recent Experience</span>
              <div className="flex flex-col gap-y-2">
                {parsedData.EXPERIENCE.map((exp, index) => (
                  <div key={index} className="flex flex-col gap-y-1">
                    <span className="font-semibold flex gap-x-2 items-center text-pretty">
                      <BusinessRounded sx={{ fontSize: "1rem" }} />
                      <span>{exp.subtitle}</span>
                    </span>
                    <span className="ml-6 text-pretty">{exp.title}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {parsedData.EDUCATION.length > 0 && (
            <div className="flex flex-col gap-y-3">
              <span className="text-xs font-semibold">Education</span>
              <div className="flex flex-col gap-y-2">
                {parsedData.EDUCATION.map((exp, index) => (
                  <div key={index} className="flex flex-col gap-y- text-pretty">
                    <span className="font-semibold flex gap-x-2 items-center overflow-hidden text-ellipsis whitespace-wrap">
                      <SchoolRounded sx={{ fontSize: "1rem" }} />
                      {exp.title}
                    </span>
                    <span className="ml-6">{exp.subtitle}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )} */}
    </div>
  );
};

const LowProfileConfidenceBanner = () => {
  return (
    <div className="flex items-center gap-4 p-4 bg-red-100 rounded-xl">
      <div className="shrink-0">
        <svg width="60" height="32" viewBox="0 0 60 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.2819 8.52628C48.1656 7.62483 49.6128 7.61044 50.5142 8.49416C56.0127 13.8844 59.4284 21.4025 59.4284 29.7143C59.4284 30.9767 58.4051 32 57.1427 32C55.8803 32 54.857 30.9767 54.857 29.7143C54.857 22.6804 51.9716 16.3245 47.314 11.7586C46.4126 10.8749 46.3982 9.42774 47.2819 8.52628Z"
            fill="#B91C1C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1465 8.52628C13.0302 9.42774 13.0158 10.8749 12.1144 11.7586C7.45682 16.3245 4.57143 22.6804 4.57143 29.7143C4.57143 30.9767 3.54808 32 2.28571 32C1.02335 32 0 30.9767 0 29.7143C0 21.4025 3.41571 13.8844 8.91418 8.49416C9.81564 7.61044 11.2628 7.62483 12.1465 8.52628Z"
            fill="#B91C1C"
          />
          <path
            opacity="0.2"
            d="M29.7143 4.57143C24.9913 4.57143 20.579 5.87147 16.8079 8.13192C15.7251 8.78093 14.3213 8.42932 13.6722 7.34657C13.0232 6.26382 13.3748 4.85995 14.4576 4.21094C18.919 1.53674 24.1406 0 29.7143 0C35.2879 0 40.5095 1.53674 44.9709 4.21094C46.0537 4.85995 46.4053 6.26382 45.7563 7.34657C45.1073 8.42932 43.7034 8.78094 42.6206 8.13192C38.8495 5.87147 34.4372 4.57143 29.7143 4.57143Z"
            fill="#B91C1C"
          />
        </svg>
      </div>
      <div className="text-red-700">
        <h3 className="font-semibold">Limited confidence profile</h3>
        <p className="text-sm">
          This profile is generated from external sources and may not be fully accurate. Please review the listed
          sources for verification.
        </p>
      </div>
    </div>
  );
};

export default ParchaReport;
