import { UIDocument } from "@/types";
import InsertDriveFileRounded from "@mui/icons-material/InsertDriveFileRounded";
import { TruncatedFilename } from "./AuditLogDocument";

const DocumentValue = ({ fileName, url, maxWidth = 200 }: UIDocument & { maxWidth?: number }) => {
  if (!fileName || !fileName?.length) {
    return null;
  }

  return (
    <a
      className="flex gap-x-1 text-xs group"
      href={url ? (url.startsWith("http") ? url : `https://${url}`) : undefined}
      target="_blank"
      rel="noopener noreferrer"
    >
      <InsertDriveFileRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} className="text-brand-purple" />
      <span className="w-full group-hover:underline text-brand-purple leading-4">
        <TruncatedFilename filename={fileName} maxWidth={maxWidth} />
      </span>
    </a>
  );
};

export default DocumentValue;
