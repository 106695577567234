import StarsIcon from "@mui/icons-material/StarsOutlined";
import IncorporationSignatureImg from "../../assets/IncorporatorSignature.png";
import { AddressValueProps } from "./AddressValue";
import { UIDocument } from "@/types";
import DocumentValue from "./DocumentValue";
import { tryToParseDate } from "@/utils";
import { twJoin } from "tailwind-merge";
import InlineCollapsibleText from "./InlineCollapsibleText";
import { WarningRounded } from "@mui/icons-material";
import WrappedValue from "./WrappedValue";

type IncorporationDocumentProps = {
  level: "success" | "failure" | "normal" | undefined;
  address: AddressValueProps;
  businessActivity: string;
  companyName: string;
  incorporationDate: string;
  companyNumber?: string | undefined;
  incorporationDocuments: UIDocument[];
};

const IncorporationDocument = ({
  address = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    countryCode: null,
    postalCode: null,
  },
  businessActivity,
  companyName,
  incorporationDate,
  incorporationDocuments,
  level,
  companyNumber,
}: IncorporationDocumentProps) => {
  if (!incorporationDocuments?.length) return null;

  const { street1, street2, city, state, countryCode, postalCode } = address;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  return (
    <div
      className={twJoin(
        "relative border w-[272px] left-3.5",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
      )}
    >
      <div
        className={twJoin(
          "absolute border-l -rotate-45 w-20 h-20 z-10 top-[-40.5px] left-[230px] bg-white",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      ></div>
      <div className="flex flex-col gap-y-4 z-0 p-5 text-xs">
        <div className="flex items-center gap-1 text-[#CECECE]">
          <StarsIcon sx={{ fontSize: "1rem" }} />
          <h2 className="uppercase">Incorporation</h2>
        </div>
        <h3 className="uppercase">{companyName}</h3>
        <div>
          {incorporationDate && (
            <div>
              <span className="font-semibold">Incorporated: </span>
              <span>{tryToParseDate(incorporationDate.replaceAll("-", "/"))}</span>
            </div>
          )}
          {companyNumber && (
            <div className="flex items-center gap-2">
              <span className="font-medium">Registration Number: </span>
              <WrappedValue text={companyNumber} level={level} />
            </div>
          )}
        </div>
        {address && !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified") && (
          <div>
            <p>{addressLine1}</p>
            <p>{addressLine2}</p>
          </div>
        )}
        <InlineCollapsibleText text={businessActivity} />
        <img width="76" height="30" src={IncorporationSignatureImg} alt="incorporator signature" />
        {incorporationDocuments?.length ? (
          <div>
            <span className="font-semibold">Incorporation Documents:</span>
            <div className="w-full flex flex-col mt-1">
              {incorporationDocuments.map((document) => (
                <div key={`${document.fileName}${document.url}`} className="flex flex-col gap-y-2">
                  <DocumentValue fileName={document.fileName} url={document.url} />
                  {document.fraudVerificationData &&
                    document.fraudVerificationData.verification_data
                      .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
                      .map((verificationDataEntry) => (
                        <div className="flex flex-col gap-y-3">
                          <div className="flex gap-x-2 text-yellow-700">
                            <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                            <p className="text-xs">
                              <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                            </p>
                          </div>
                        </div>
                      ))}
                  {document.summary && (
                    <div className="flex flex-col gap-y-1 mb-4">
                      <p className="font-semibold">Summary</p>
                      <InlineCollapsibleText text={document.summary} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IncorporationDocument;
